<template>
  <div>
    <div class="section-title section-title_app">
      <h3>Ventas Call Center</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <b-tooltip label="Nueva">
            <b-button icon-left="file" size="is-large" type="is-primary" @click="clearSalesCallCenter"></b-button>
          </b-tooltip>
          <b-tooltip label="Ver PDF">
            <b-button icon-left="eye" size="is-large" @click="showPDF = true" :disabled="!has_orders"></b-button>
          </b-tooltip>
          <b-tooltip label="Guardar">
            <b-button icon-left="save" size="is-large" @click="onSave" :disabled="readOnly"></b-button>
          </b-tooltip>
          <b-tooltip label="Enviar">
            <b-button icon-left="envelope" size="is-large" :disabled="!has_orders"></b-button>
          </b-tooltip>
          <b-tooltip label="Buscar">
            <b-button icon-left="search" size="is-large" @click="getData"></b-button>
          </b-tooltip>
          <b-tooltip label="Generar Orden"
                     v-if="$alfalab.isAdmin($auth.user())"
          >
            <b-button icon-left="laptop-medical" size="is-large" @click="createOrder" :disabled="has_orders"></b-button>
          </b-tooltip>
          <div v-if="readOnly">
            <p>Estado: {{ statusFormatted }}</p>
            <a>URL: {{ url }}</a>
          </div>
          <b-tooltip
              v-if="patient && patient.lapifan_active && patient.lapifan_active.status"

              label="Lapifan">
            <b-button
                @click="handleLapifanClick"
                size="is-large"
                style="color: gold"
                icon-left="crown"></b-button>
          </b-tooltip>
        </div>
      </div>
      <div class="column is-12 is-7-desktop is-8-widescreen is-9-fullhd has-background-white is-multiline">
        <div class="columns is-12 column  is-multiline">
          <div class="column is-4">
            <b-field label="Fecha de venta"
                     label-position="on-border">
              <b-input v-model="date" :disabled="true"></b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="Folio"
                     label-position="on-border">
              <b-input v-model="code" :disabled="readOnly"/>
            </b-field>
          </div>
          <div class="column is-12" v-if="! hasPatient">
            <select-patient class="columns"
                            type="salescallcenter"
                            :patient.sync="patient"
            ></select-patient>
          </div>
        </div>

        <div class="column is-12">
          <b-field grouped group-multiline>
            <p class="control">
              <button class="button field is-danger"
                      @click="clearStudies"
                      :disabled="!checkedRows.length">
                <b-icon icon="eraser"></b-icon>
              </button>
            </p>
            <p class="control">
              <button :disabled="!checkedRows.length"
                      class="button"
                      @click="onDeleteItem">
                <b-icon icon="trash"></b-icon>
              </button>
            </p>
            <p class="control">
              <select-study @selectStudy="onSelectStudy" v-if="!readOnly"></select-study>
            </p>
          </b-field>
        </div>
        <div class="column ">
          <b-table
              :checkable="true"
              :checked-rows.sync="checkedRows"
              :data="studies">
            <b-table-column v-slot="props" label="Promoción" field="companyName">{{
                props.row.companyName
              }}
            </b-table-column>
            <b-table-column v-slot="props" label="Código" field="studyCode">{{ props.row.studyCode }}</b-table-column>
            <b-table-column v-slot="props" label="Estudio" field="studyName">{{ props.row.studyName }}</b-table-column>
            <b-table-column v-slot="props" label="Precio" field="price">{{ props.row.price }}</b-table-column>
            <b-table-column v-slot="props" label="Indicaciones" field="instructions">{{
                props.row.instructions
              }}
            </b-table-column>
            <b-table-column v-slot="props" label="D.Entrega" field="deliveryDays">{{
                props.row.delivery_days
              }}
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                        icon="box-open"
                        size="is-large">
                    </b-icon>
                  </p>
                  <p>No hay estudios seleccionados.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>

      <div class="column is-5-desktop is-4-widescreen is-3-fullhd has-background-white"
           style="border-left: 2px #dbdbdb solid">
        <div style="">
          <div class="column is-12">
            <div class="field is-size-6" style="margin-bottom: 1em">
              <template v-if="hasPatient">
                <div class="is-divider" data-content="Paciente"></div>
                <data-sales-call-center
                    :data="patient"
                    :read-only="hasId"
                    :allow-edit="!hasId"
                ></data-sales-call-center>
              </template>
              <div class="is-divider" data-content="Importe"></div>
              <span class="is-pulled-left">Subtotal: </span>
              <span class="is-pulled-right">{{ subTotal | currency }}</span> <br>

              <b-checkbox v-model="tax">
                IVA:
              </b-checkbox>
              <span v-if="tax" class="is-pulled-right">{{ taxes | currency }}</span>
              <span v-else class="is-pulled-right">$0.00</span>
              <br>
              <span class="is-pulled-right"></span>
              <span class="is-pulled-right"></span> <br>
              <span class="is-pulled-left has-text-weight-bold">Total: </span>
              <span v-if="tax" class="is-pulled-right">{{ totalWithTax | currency }}</span>
              <span v-else class="is-pulled-right">{{ total | currency }}</span> <br>
            </div>
            <br>
            <template v-if="$alfalab.enablePaymentFormQuotation(this)">
              <b-field label="Forma de pago">
                <b-select expanded required>
                  <option v-for="method in methods" :key="method.code" :value="method.code">{{ method.description }}
                  </option>
                </b-select>
              </b-field>
            </template>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showPDF">
      <iframe class="lapi__iframe" :src="`${urlApi}/phone-sell/preview/${code}`"></iframe>
    </b-modal>
    <b-modal v-model="showLapifan" v-if="patient && patient.lapifan_active && patient.lapifan_active.status">
      <lapifan-modal :patient="patient"/>
    </b-modal>
    <b-modal v-model="showListSalesCallCenter" has-modal-card full-screen>
      <modal-select-sale :data-sales="dataSales"
                         @search="onModalSearch"
                         @page-change="onPageChange"
                         @select="onSelect" ></modal-select-sale>
    </b-modal>
  </div>
</template>

<script>
import SelectPatient from "@/components/patient/SelectPatient";
import SelectStudy from "@/components/sales-call-center/SelectStudy";
import LapifanModal from "../../components/lapifan/LapifanModal.vue";
import {mapState, mapGetters} from "vuex";
import salescallcenter from "@/store/modules/salescallcenter";
import ListSalesCallCenter from "@/components/sales-call-center/ModalSelectSale";
import ModalSelectSale from "@/components/sales-call-center/ModalSelectSale";
import DataSalesCallCenter from "@/components/sales-call-center/DataSalesCallCenter";

export default {
  name: "Index",
  components: {
    DataSalesCallCenter,
    ModalSelectSale,
    LapifanModal,
    SelectPatient,
    SelectStudy,
    ListSalesCallCenter
  },
  props: {
    data: Array,

  },
  data() {
    return {
      checkedRows: [],
      showPDF: false,
      showLapifan: false,
      showListSalesCallCenter: false,
      studies: [],
      tax: true,
      code: '',
      status: '',
      readOnly: false,
      dataSales: {},
      search: '',
      query: {},
      date: '',
      url: '',
      has_orders: false,
    }
  },
  computed: {
    //...mapState('salescallcenter',['studies']),
    ...mapState({
      methods: state => state.main.paymentTypes
    }),
    ...mapGetters('salescallcenter', ['hasPatient', 'hasId']),
    patient: {
      set(value) {
        this.$store.dispatch('salescallcenter/setPatient', value);
      },
      get() {
        return this.$store.state.salescallcenter.patient;
      }
    },
    subTotal() {
      return this.studies.reduce((acc, item) => acc + item.price, 0)
    },
    taxes() {
      const tax = .16;
      return parseFloat(this.studies.reduce((acc, item) => acc + item.price, 0) * tax).toFixed(2);
    },
    totalWithTax() {
      const tax = 1.16;
      return parseFloat(this.studies.reduce((acc, item) => acc + item.price, 0) * tax).toFixed(2);
    },
    total() {
      const tax = 1;
      return parseFloat(this.studies.reduce((acc, item) => acc + item.price, 0) * tax).toFixed(2);
    },
    statusFormatted() {
      const values = {PENDIENTE: 'EN ESPERA DE PAGO'}
      return values[this.status] ?? 'NO DEFINIDO'
    },
    urlApi(){
      return process.env.VUE_APP_API_URL;
    }
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
  },
  methods: {
    clearSalesCallCenter() {
      this.$loader.show();
      this.studies = [];
      this.readOnly = false;
      this.code = '';
      this.byCode = false;
      this.has_orders = false;
      this.status = '';
      this.$store.dispatch('salescallcenter/clearSalesCallCenter').finally(() => {
        this.$loader.hide();
      })
    },
    handleLapifanClick() {
      this.showLapifan = true;
    },
    handleListSalesCallCenter() {
      this.showListSalesCallCenter = true;
    },
    onSelectStudy(data) {
      this.studies.push(data);
      this.tax = !data.noTax;
      if(!this.tax){
        console.log('sin iva');
        this.$buefy.dialog.confirm({
          title: 'Estudio sin IVA',
          message: 'Atención este estudio no contiene IVA',
          confirmText: 'Aceptar',
          cancelText: 'Cerrar'
        });
      }
      this.studies = [...this.studies];
      //console.log('index:',this.studies)
    },
    clearStudies() {
      this.studies = [];
    },
    onDeleteItem() {
      this.studies = this.studies.filter(study => !this.checkedRows.includes(study));
      this.checkedRows = [];
    },
    onSave() {
      const params = Object.assign({}, {
        patient: {code: this.patient.code},
        studies: this.studies.map(study => {
          return {
            code: study.studyCode,
            tax: this.tax ? 16 : 0,
            company: study.companyCode,
          }
        }),
      });
      if (this.patient && this.patient.lapifan_active && this.patient.lapifan_active.status) {
        this.loading = true;
        this.$buefy.dialog.confirm({
          title: 'Guardar Venta',
          message: '¿Está seguro de guardar? Recuerde que después de guardar no puede realizar modificaciones',
          confirmText: 'Si, guardar',
          cancelText: 'No, cancelar',
          onConfirm: () => {
            this.$loader.show();
            this.$http.post(`/phone-sell`, params).then(({data}) => {
              console.log(data);
              this.$toast.success('Guardado correctamente');
              this.readOnly = true;
              this.code = data.data.code;
              this.status = data.data.status
            }).catch(errors => {
              this.$alfalab.showError(errors);
            }).finally(() => {
              this.$loader.hide();
            })
          }
        })
      } else if (this.patient || !this.patient.lapifan_active || !this.patient.lapifan_active.status) {
        const hasLapiFan = this.studies.some(s => s.studyCode === 'LAPIFAN');
        const requireLapiFan = this.studies.some(study => study.requireLapifan );
        console.log('lapifan', hasLapiFan, requireLapiFan)
          if(hasLapiFan) {
            console.log('los estudios contienen lapifan');
            this.loading = true;
            this.$buefy.dialog.confirm({
              title: 'Guardar Venta',
              message: '¿Está seguro de guardar? Recuerde que después de guardar no puede realizar modificaciones',
              confirmText: 'Si, guardar',
              cancelText: 'No, cancelar',
              onConfirm: () => {
                this.$loader.show();
                this.$http.post(`/phone-sell`, params).then(({data}) => {
                  console.log(data);
                  this.$toast.success('Guardado correctamente');
                  this.readOnly = true;
                  this.code = data.data.code;
                  this.status = data.data.status
                }).catch(errors => {
                  this.$alfalab.showError(errors);
                }).finally(() => {
                  this.$loader.hide();
                })
              }
            })

          } else {


                console.log(requireLapiFan);
                  if(requireLapiFan){
                    console.log('los estudios no tienen lapifan')
                    this.loading = true;
                    this.$buefy.dialog.confirm({
                      title: 'Paciente sin lapifan',
                      message: 'El paciente seleccionado no cuenta con lapifan, no se puede aplicar promoción' +
                          ' Favor de verificar.',
                      confirmText: 'Aceptar',
                      cancelText: 'Cerrar',
                    });
                    this.$loader.hide();
                  } else if(!requireLapiFan){
                    this.loading = true;
                    this.$buefy.dialog.confirm({
                      title: 'Guardar Venta',
                      message: '¿Está seguro de guardar? Recuerde que después de guardar no puede realizar modificaciones',
                      confirmText: 'Si, guardar',
                      cancelText: 'No, cancelar',
                      onConfirm: () => {
                        this.$loader.show();
                        this.$http.post(`/phone-sell`, params).then(({data}) => {
                          console.log(data);
                          this.$toast.success('Guardado correctamente');
                          this.readOnly = true;
                          this.code = data.data.code;
                          this.status = data.data.status
                        }).catch(errors => {
                          this.$alfalab.showError(errors);
                        }).finally(() => {
                          this.$loader.hide();
                        })
                      }
                    })
                  }

          }
      }
    },
    getData() {
      this.$loader.show();
      const params = {
        ...this.query,
        page: this.page,
        perPage: 20,
      };
      this.$http.get(`/phone-sell`, {params}).then(async ({data}) => {
        this.dataSales = data;
        this.handleModalSelectSale();
      }).catch(errors => {

      }).finally(() => {
        this.loading = false;
        this.$loader.hide();
      });
    },
    handleModalSelectSale() {
      this.showListSalesCallCenter = true;
    },
    async onModalSearch(query) {
      console.log('query', query)
      this.query = query;
      this.page = 1;
      await this.getData();
    },
    onSelect(selectedValue) {
      this.$loader.show();
      this.$http.get(`/phone-sell/${selectedValue.code}`).then(async ({data}) => {
        const phoneSell = data.data;
        this.code = phoneSell.code;
        this.studies = phoneSell.studies;
        this.date = phoneSell.date;
        this.readOnly = true;
        this.tax = !phoneSell.noTax;
        this.url=phoneSell.url;
        this.status=phoneSell.status;
        this.has_orders=phoneSell.has_orders;
        //this.patient = phoneSell.patient;
        this.$store.dispatch("salescallcenter/setPatient", phoneSell.patient);
      }).catch(errors => {
      }).finally(() => {
        this.loading = false;
        this.$loader.hide();
      });
    },
    onPageChange(page){
      this.page = page;
      this.both = true;
      this.getData();
    },
    createOrder(){
      const params = Object.assign({}, {
        code: this.code
      });
      this.loading = true ;
      this.$buefy.dialog.confirm({
        title: 'Generar Orden',
        message: '¿Está seguro de generar la orden?',
        confirmText: 'Si, generar orden',
        cancelText: 'No, cancelar',
        onConfirm: () => {
          this.$loader.show();
          this.$http.post(`/phone-sell-by-admin`, params).then(({data}) => {
            this.$toast.success('Orden creada correctamente');
            this.readOnly = true;
            this.code = data.code;
          }).catch(errors => {
            this.$alfalab.showError(errors);
          }).finally(() => {
            this.$loader.hide();
          })
        }
      });
    },
  }
}
</script>

<style scoped>
.lapi__iframe {
  width: 100%;
  height: 80vh;
}
</style>