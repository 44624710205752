<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Selección de estudios</p>
    </header>
    <section class="modal-card-body">
      <b-table
        :data="data.data"
        searchable
        :selected.sync="selected"
        @dblclick="onSelect"
        paginated
        per-page="20"
        :backend-filtering="true"
        pagination-simple
        :current-page="data.meta ? parseInt(data.meta.page) : parseInt(1)"
        :total="data.meta ? parseInt(data.meta.total): 0"
        backend-pagination
        @filters-change="onFiltersChange"
        @page-change="onPageChange"
        >
        <b-table-column field="companyName" v-slot="props" label="Promoción">{{ props.row.companyName }}</b-table-column>
        <b-table-column v-slot="props" label="Precio">{{ props.row.price }}</b-table-column>
        <b-table-column searchable field="studyCode" v-slot="props" label="Código">{{ props.row.studyCode }}</b-table-column>
        <b-table-column searchable field="studyName" v-slot="props" label="Nombre">{{ props.row.studyName }}</b-table-column>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                    icon="frown"
                    size="is-large">
                </b-icon>
              </p>
              <p>Lo sentimos no hay ningún estudio que coincida con tu búsqueda.</p>
            </div>
          </section>
        </template>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Cerrar</button>
      <button class="button is-primary" type="button" @click="onSelect(selected)">Seleccionar</button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ModalSelectStudy",
  data() {
    return {
      selected: {},
    }
  },
  props: {
    data: Object,
  },
  methods: {
   onSelect(data){
     this.$parent.close();
     this.$emit('select', data)
   },
    onFiltersChange(params){
     console.log('search', params)
     this.$emit('search',params);
    },
    onPageChange(page){
      this.$emit('page-change', page);
    }
  },
}
</script>

<style scoped>

</style>