<template>
  <b-field label="Buscar Estudios" label-position="on-border" grouped>
    <b-input @keypress.native.enter="getData"
             v-model="study"></b-input>
    <b-checkbox v-model="byCode"
                value="true"
                unchecked-value="false">
      Por clave
    </b-checkbox>
    <p class="control">
      <b-button class="button is-primary"
                type="button"
                @click="getData">
        <b-icon icon="search"></b-icon>
      </b-button>
    </p>
    <b-modal v-model="showModalSelectStudy" has-modal-card full-screen>
      <modal-select-study :data="data"
                          @page-change="onPageChange"
                          @search="onModalSearch" @select="onSelect"></modal-select-study>
    </b-modal>
  </b-field>
</template>

<script>
import ModalSelectStudy from "@/components/sales-call-center/ModalSelectStudy";

export default {
  name: "SelectStudy",
  data() {
    return {
      showModalSelectStudy: false,
      study: '',
      data: {},
      byCode: false,
      search: '',
      both: false,
      studies: [],
      page: 1,
      query: {}
    }
  },
  props: {},
  computed: {},
  components: {
    ModalSelectStudy
  },
  methods: {
    handleModalSelectStudy() {
      this.showModalSelectStudy = true;
    },
    getData() {
      this.$loader.show();
      const params = {
        by_code: this.byCode ? 1 : 0,
        name: this.study,
        perPage: 20,
        both: this.both ? 1: 0,
        page: this.page,
        ...this.query
      };
      this.handleModalSelectStudy();
      this.$http.get(`callcenter/search-studies`, {params}).then(async ({data}) => {
        this.data = data;
        this.handleModalSelectStudy();
      }).catch(errors => {

      }).finally(() => {
        this.loading = false;
        this.$loader.hide();
      });
    },
    async onModalSearch(query){
      console.log('query', query)
      this.byCode = false;
      this.both = true;
      this.query = query;
      this.page = 1;
      await this.getData();
      this.both = false;
    },
    onSelect(data){
      //this.studies=data;
      console.log(this.studies)
      this.$emit('selectStudy',data);
      this.study = ''
    },
    onPageChange(page){
      this.page = page;
      this.both = true;
      this.getData();
    }
  },
}
</script>

<style scoped>

</style>