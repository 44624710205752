import { render, staticRenderFns } from "./SelectStudy.vue?vue&type=template&id=2cf15f68&scoped=true"
import script from "./SelectStudy.vue?vue&type=script&lang=js"
export * from "./SelectStudy.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cf15f68",
  null
  
)

export default component.exports